@import '../constants/mixins';
@import '../constants/generics';
@import '../constants/fontface';

.in-voteform {
  width: 100%;
  z-index: 20;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  background: white;
  filter: drop-shadow(15px 0px 35px rgba(0,0,0,0.2));

  @include tablet {
    height: 100%;
    overflow: visible;
  }

  &::-webkit-scrollbar {
    height: 100%;
    width: .5rem;
  }
  
  &::-webkit-scrollbar-track {
    background:#E6E6E6;
    border-radius: 5rem;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #F39200;
    border-radius: 5rem;
  }

  .in-voteform-bg {
    &>img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  .in-voteform-grid {
    position: relative;
    @include inDisplayGrid();

    .in-voteform-grid-sup {
      height: 12rem;
      @include inDisplayGrid($columns:1fr 1.75fr);    
      
      @include tablet {
        height: inherit;
      }
      
      @include mobile {
        height: 12rem;
      }
      
      .in-voteform-grid-sup-lf {
        &>img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      
      .in-voteform-grid-sup-rt {
        padding: 1.5rem 5rem;
        background: #F5F5F5;
        @include inDisplayGrid($align:center);

        @include wide-lg {
          padding: 1.5rem 2.5rem;
        }

        @include mobile {
          padding: 1.5rem;
          align-items: flex-end;
        }

        .in-voteform-grid-sup-rt-ct {
          @include inDisplayGrid($gap:.5rem);

          &>p:nth-child(1) {
            @include inFont($color:#F39200, $size:1.25rem, $height:1.5rem, $family:'Microbrew Soft Four 3D');
          }

          &>p:nth-child(2) {
            @include inFont($color:#7B3603, $size:1.75rem, $height:2rem, $weight:700);
          }
        }
      }
    }

    .in-voteform-grid-sub {
      padding: 2.5rem;
      @include inDisplayGrid($gap:2.5rem);

      @include wide-sm {
        padding: 1.5rem;
      }

      @include tablet {
        padding: 2.5rem;
      }

      @include wide-sm {
        padding: 1.5rem;
      }

      .in-voteform-grid-sub-search {
        position: relative;
        @include inDisplayGrid($gap:1.5rem);

        .in-voteform-grid-sub-search-input {
          position: relative;

          &>input {
            width: 100%;
            background: white;
            border-radius: .5rem;
            border: .1rem solid #E6E6E6;
            padding: 1rem 5rem 1rem 2rem;
            @include inFont($color:#808080, $size:1rem, $height:1rem, $weight:600);
            
            &::placeholder {
              @include inFont($color:#808080, $size:1rem, $height:1rem, $weight:600);
            }

            &:focus {
              transition: .25s ease-in;
              filter: drop-shadow(4.5px 7.794px 7.5px rgba(0,0,0,0.1));
            }
          }

          &>img {
            top: 50%;
            right: 1.5rem;
            position: absolute;
            transform: translate(-50%, -50%);
          }

          &>svg {
            top: 40%;
            right: 1.5rem;
            position: absolute;
            font-size: 1.1rem;
            transform: translate(-50%, -50%);
            color: #7E3D0A;
          }
        }

        .in-voteform-grid-sub-search-btn {
          text-align: right;

          &>button {
            padding: .5rem 1.5rem;
            border-radius: 2.5rem;
            border: .15rem solid #F39200;
            background-image: linear-gradient(90deg, #f39100 0%, #f2a430 100%);
            @include inFont($color:white, $size:1.25rem, $height:1.25rem, $family:'Microbrew Soft Four 3D');
            
            &:disabled{
              opacity: 0.5;
            }

            &:hover {
              transition: .25s ease-in;
              filter: drop-shadow(7.5px 12.99px 10px rgba(243,146,0,0.3));
            }
          }
        }

        .in-voteform-grid-sub-search-ancor {
          &>p {
            text-align: right;
            @include inFont($color:#F39405, $size:1rem, $height:1.5rem, $weight:600);

            &>span {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        .in-voteform-grid-sub-search-results {
          left: 0;
          top: 4rem;
          width: 100%;
          padding: 2.5rem;
          position: absolute;
          background: white;
          border-radius: .5rem;
          border: .1rem solid #E6E6E6;
          filter: drop-shadow(4.5px 7.794px 7.5px rgba(0,0,0,0.1));

          @include mobile {
            padding: 1.5rem;
          }

          .in-voteform-grid-sub-search-results-scrollbar {         
            height: 100%;
            overflow-y: auto;
            max-height: 15rem;
        
            &::-webkit-scrollbar {
              height: 100%;
              width: .35rem;
            }
            
            &::-webkit-scrollbar-track {
              background:#E6E6E6;
              border-radius: 5rem;
            }
            
            &::-webkit-scrollbar-thumb {
              background: #F39200;
              border-radius: 5rem;
            }
       
            &>ul {
              @include inDisplayGrid($gap:1.5rem);
              
              &>li {
                cursor: pointer;
                @include inDisplayGrid($gap:1rem, $align:center, $columns:auto 1fr);
  
                &>span {
                  @include inFont($color:#808080, $size:1rem, $height:1.25rem, $weight:600);
                  flex: 1;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 85%;
                }
              }

              .no-result{
                @include inDisplayGrid($align:center, $columns:1fr);
              }
            }
          }
        }
      }

      .in-voteform-grid-sub-register {
        @include inDisplayGrid($gap:1.5rem);

        .in-voteform-grid-sub-register-title {
          &>p {
            text-align: center;
            @include inFont($color:#7B3603, $size:2.25rem, $height:2.5rem, $family:'Microbrew Soft Four 3D');
          }
        }

        .in-voteform-grid-sub-register-form {
          @include inDisplayGrid($gap:1rem);

          &>input,
          &>select {
            width: 100%;
            padding: 1rem 2rem;
            background: white;
            border-radius: .5rem;
            border: .1rem solid #E6E6E6;
            @include inFont($color:#808080, $size:1rem, $height:1rem, $weight:600);
            
            &::placeholder {
              @include inFont($color:#808080, $size:1rem, $height:1rem, $weight:600);
            }

            &:focus {
              transition: .25s ease-in;
              filter: drop-shadow(4.5px 7.794px 7.5px rgba(0,0,0,0.1));
            }
          }

          &>button {
            margin-right: 0;
            margin-left: auto;
            padding: .5rem 1.5rem;
            border-radius: 2.5rem;
            border: .15rem solid #F39200;
            background-image: linear-gradient(90deg, #f39100 0%, #f2a430 100%);
            @include inFont($color:white, $size:1.25rem, $height:1.25rem, $family:'Microbrew Soft Four 3D');
            
            &:disabled{
              opacity: 0.5;
            }

            &:hover {
              transition: .25s ease-in;
              filter: drop-shadow(7.5px 12.99px 10px rgba(243,146,0,0.3));
            }
          }
        }

        .in-voteform-grid-sub-register-back {          
          text-align: right;
          
          &>span {
            cursor: pointer;
            text-decoration: underline;
            @include inFont($color:#F39405, $size:1rem, $height:1.5rem, $weight:600);
          }
        }
      }

      .in-voteform-grid-sub-confirm {
        @include inDisplayGrid($gap:2.5rem);

        .in-voteform-grid-sub-confirm-title {
          @include inDisplayGrid($gap:1rem, $text:Center);
          
          &>p:nth-child(1),
          &>p:nth-child(3) {
            @include inFont($color:#7B3603, $size:1.25rem, $height:1.75rem, $weight:700);
          }
          
          &>p:nth-child(2) {
            @include inFont($color:#7B3603, $size:3rem, $height:3.5rem, $family:'Microbrew Soft Four 3D');
          }
        }

        .in-voteform-grid-sub-confirm-email {
          @include inDisplayGrid($gap:2.5rem);

          .in-voteform-grid-sub-confirm-email-input {
            @include inDisplayGrid($gap:1.5rem);

            &>input {
              width: 100%;
              padding: 1rem 2rem;
              background: white;
              border-radius: .5rem;
              border: .1rem solid #E6E6E6;
              @include inFont($color:#808080, $size:1rem, $height:1rem, $weight:600);
              
              &::placeholder {
                @include inFont($color:#808080, $size:1rem, $height:1rem, $weight:600);
              }
  
              &:focus {
                transition: .25s ease-in;
                filter: drop-shadow(4.5px 7.794px 7.5px rgba(0,0,0,0.1));
              }
            }
  
            &>button {
              margin-right: 0;
              margin-left: auto;
              padding: .5rem 1.5rem;
              border-radius: 2.5rem;
              border: .15rem solid #F39200;
              background-image: linear-gradient(90deg, #f39100 0%, #f2a430 100%);
              @include inFont($color:white, $size:1.25rem, $height:1.25rem, $family:'Microbrew Soft Four 3D');
              
              &:disabled{
                opacity: 0.5;
              }

              &:hover {
                transition: .25s ease-in;
                filter: drop-shadow(7.5px 12.99px 10px rgba(243,146,0,0.3));
              }
            }
            
            &>span {
              cursor: pointer;
              text-align: right;
              text-decoration: underline;
              @include inFont($color:#F39405, $size:1rem, $height:1.5rem, $weight:600);
            }
          }

          .in-voteform-grid-sub-confirm-email-or {
            @include inDisplayGrid($gap:1.5rem, $align:center, $justify:center, $columns:1fr auto 1fr);

            &>div:nth-child(1),
            &>div:nth-child(3) {
              width: 100%;
              height: .15rem;
              background: #F5EFEA;
            }

            &>span {
              @include inFont($color:#F39405, $size:1rem, $height:1rem, $weight:600);
            }
          }

          .in-voteform-grid-sub-confirm-email-btn {
            @include inDisplayGrid($gap:1.5rem);

            &>button {
              height: 3.5rem;
              background: white;
              padding: .5rem 1.5rem;
              border-radius: 2.5rem;
              border: .15rem solid #E3E3E3;
              @include inDisplayGrid($gap:1rem, $align:center, $justify:center, $columns:repeat(2,auto));

              &:hover {
                transition: .25s ease-in;
                filter: drop-shadow(4.5px 7.794px 7.5px rgba(0,0,0,0.1));
              }

              &>span {
                @include inFont($color:#1C2D41, $size:1rem, $height:1rem, $weight:400);
              }
            }
          }
        }
      }
    }
  }
}