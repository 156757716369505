/* Meidas Queries */
$wide-xl:1680px;
$wide-lg:1440px;
$wide-md:1220px;
$wide-sm:1024px;
$desktop:992px;
$tablet:768px;
$mobile:576px;

//1660px;
@mixin wide-xl {
  @media (max-width: #{$wide-xl}) {
    @content;
  }
}

//1440px;
@mixin wide-lg {
  @media (max-width: #{$wide-lg}) {
    @content;
  }
}

//1220px;
@mixin wide-md {
  @media (max-width: #{$wide-md}) {
    @content;
  }
}

//1024px;
@mixin wide-sm {
  @media (max-width: #{$wide-sm}) {
    @content;
  }
}

//992px;
@mixin desktop {
  @media (max-width: #{$desktop}) {
    @content;
  }
}

//768px;
@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

//576px;
@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}
