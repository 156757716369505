@import 'constants/mixins';
@import 'constants/generics';
@import 'constants/fontface';

#in-nf {
  width: 100%;
  z-index: 22;
  height: 100vh;
  position: relative;

  .in-nf-bg {
    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  .in-nf-txt {
    height: 100%;
    position: relative;
    @include inContainer($maxWidth:85%);
    @include inDisplayGrid($align:center, $justify:center);

    .in-nf-txt-ct {
      @include inDisplayGrid($gap:2.5rem, $align:center, $text:center, $justify:center);
      
      &>div:nth-child(1) {
        @include inDisplayGrid($gap:1rem, $align:center, $text:center, $justify:center);
  
        &>h1 {
          @include inFont($color:#FF9400, $size:20rem, $height:17.5rem, $family:'Microbrew Soft Four 3D');
  
          @include mobile {
            font-size: 15rem;
            line-height: 12.5rem;
          }
        }
  
        &>p:nth-child(2) {
          @include inFont($color:#7A3602, $size:2.5rem, $height:2rem, $family:'Microbrew Soft Four 3D');
  
          @include mobile {
            font-size: 2rem;
            line-height: 2rem;
          }
        }
  
        &>p:nth-child(3) {
          @include inFont($color:#7A3602, $size:1rem, $height:1.5rem);
        }
      }

      &>div:nth-child(2) {
        &>p {
          text-decoration: underline;
          @include inFont($color:#7A3602, $size:1.25rem, $height:1.5rem);
        }
      }
    }
  }
}
