@import '../constants/mixins';
@import '../constants/generics';
@import '../constants/fontface';

.in-vote {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1060;
  background: rgba(0, 0, 0, 0.8);

  .in-vote-modal {
    top: 5%;
    width: 100%;
    max-width: 30%;
    overflow: auto;
    margin: 0 auto;
    max-height: 90%;
    position: relative;
    border-radius: 1rem;
    background: #F6F0EB;
    border: .1rem solid #c0c0c0;

    &::-webkit-scrollbar {
      width: 0;
    }

    @include wide-lg {
      max-width: 40%;
    }

    @include wide-md {
      max-width: 50%;
    }

    @include wide-sm {
      max-width: 60%;
    }

    @include desktop {
      max-width: 70%;
    }

    @include tablet {
      max-width: 80%;
    }

    @include mobile {
      max-width: 90%;
    }

    .in-vote-modal-grid {
      padding: 2.5rem 5rem;
      @include inDisplayGrid($gap: 2.5rem, $text: center);

      @include wide-md {
        padding: 2.5rem;
      }

      @include mobile {
        padding: 1.5rem;
      }

      .in-vote-modal-grid-title {
        @include inDisplayGrid($gap: 1rem, $text: Center);

        &>p:nth-child(1),
        &>p:nth-child(3) {
          @include inFont($color: #7B3603, $size: 1.25rem, $height: 1.75rem, $weight: 700);
        }

        &>p:nth-child(2) {
          @include inFont($color: #F39200, $size: 4rem, $height: 3.5rem, $family: 'Microbrew Soft Four 3D');
        }
      }

      .in-vote-modal-grid-email {
        @include inDisplayGrid($gap: 2.5rem);

        .in-vote-modal-grid-email-input {
          @include inDisplayGrid($gap: 1.5rem);

          &>input {
            width: 100%;
            padding: 1rem 2rem;
            background: white;
            border-radius: .5rem;
            border: .1rem solid #E6E6E6;
            @include inFont($color: #808080, $size: 1rem, $height: 1rem, $weight: 600);

            &::placeholder {
              @include inFont($color: #808080, $size: 1rem, $height: 1rem, $weight: 600);
            }

            &:focus {
              transition: .25s ease-in;
              filter: drop-shadow(4.5px 7.794px 7.5px rgba(0, 0, 0, 0.1));
            }
          }

          &>button {
            margin-right: 0;
            margin-left: auto;
            padding: .5rem 1.5rem;
            border-radius: 2.5rem;
            border: .15rem solid #F39200;
            background-image: linear-gradient(90deg, #f39100 0%, #f2a430 100%);
            @include inFont($color: white, $size: 1.25rem, $height: 1.25rem, $family: 'Microbrew Soft Four 3D');

            &:disabled {
              opacity: 0.5;
            }

            &:hover {
              transition: .25s ease-in;
              filter: drop-shadow(7.5px 12.99px 10px rgba(243, 146, 0, 0.3));
            }
          }

          &>span {
            cursor: pointer;
            text-align: right;
            text-decoration: underline;
            @include inFont($color: #F39405, $size: 1rem, $height: 1.5rem, $weight: 600);
          }
        }

        .in-vote-modal-grid-email-or {
          @include inDisplayGrid($gap: 1.5rem, $align: center, $justify: center, $columns: 1fr auto 1fr);

          &>div:nth-child(1),
          &>div:nth-child(3) {
            width: 100%;
            height: .15rem;
            background: #F5EFEA;
          }

          &>span {
            @include inFont($color: #F39405, $size: 1rem, $height: 1rem, $weight: 600);
          }
        }

        .in-vote-modal-grid-email-btn {
          @include inDisplayGrid($gap: 1.5rem);

          &>button {
            height: 3.5rem;
            background: white;
            padding: .5rem 1.5rem;
            border-radius: 2.5rem;
            border: .15rem solid #E3E3E3;
            @include inDisplayGrid($gap: 1rem, $align: center, $justify: center, $columns: repeat(2, auto));

            &:hover {
              transition: .25s ease-in;
              filter: drop-shadow(4.5px 7.794px 7.5px rgba(0, 0, 0, 0.1));
            }

            &>span {
              @include inFont($color: #1C2D41, $size: 1rem, $height: 1rem, $weight: 400);
            }
          }
        }
      }
    }
  }
}