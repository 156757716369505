@import '../constants/mixins';
@import '../constants/generics';
@import '../constants/fontface';

.wrapper-regulamento{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.15);

  .modal{
    left: 20%;
    right: 20%;
    top: 2rem;
    bottom: 2rem;
    padding: 2rem 0;
    position: absolute;
    background: white;
    border-radius: .5rem;
    border: 1px solid #C0C0C0;

    @include mobile{
      left: 5%;
      right: 5%;
    }

    .container{
      flex-direction: column;
      @include inContainer($maxWidth:80%);
      @include inDisplayGrid($display:flex, $gap:1.2rem, $text:center);

      .title{
        text-align: center;
      }

      .content{
        height: 85%;
        overflow: auto;
        text-align: start;
      }

      .actions{
        padding: 1rem;

        button{
          padding: 1rem;
          border-radius: .3rem;
          background: #F39200;
          color: white!important;
          @include inFont($weight: 700);
        }
      }
    }
  }
}