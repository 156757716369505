@import 'constants/mixins';
@import 'constants/generics';
@import 'constants/fontface';

.cc-color-override-1444386161.cc-window {
  display: none;
}

.in-votenow {
  right: 5rem;
  top: 5rem;
  position: fixed;
  z-index: 99999;

  @include wide-md {
    right: 2.5rem;
    top: 2.5rem;
  }

  @include mobile {
    right: 50%;
    top: inherit;
    bottom: 2.5rem;
    transform: translate(50%, 0%);
  }

  &>span {
    margin: 0 auto;
    padding: 1rem 2rem;
    border-radius: 2.5rem;
    border: 0.15rem solid #f39200;
    animation: shadow-pulse 1.5s infinite;
    background-image: linear-gradient(90deg, #f39100 0%, #f2a430 100%);
    @include inFont($color: white !important,
      $size: 1.5rem,
      $height: 2rem,
      $family: 'Microbrew Soft Four 3D'
    );
    cursor: pointer;

    &:hover {
      transition: 0.25s ease-in-out;
      filter: drop-shadow(7.5px 12.99px 10px rgba(243, 146, 0, 0.3));
    }
  }
}

.in-init {
  width: 100%;
  min-height: 100vh;
  position: relative;

  .in-init-bg {
    &>img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  .in-init-line {
    top: 1.5rem;
    width: 100%;
    position: absolute;

    &>hr {
      border-top: 0.1rem dashed #793501;
    }
  }

  .in-init-socials {
    top: 50%;
    left: 2.5%;
    position: absolute;
    transform: translate(0%, -50%);
    @include inDisplayGrid($align: center);

    @include mobile {
      left: 50%;
      top: inherit;
      bottom: 5rem;
      transform: translate(-50%, 0%);
    }

    .in-init-socials-center {
      height: 100%;
      @include inDisplayGrid($gap: 1.5rem);

      @include mobile {
        grid-template-columns: repeat(2, auto);
      }

      &>a {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background: #f2ebe6;
        @include inDisplayGrid($display: flex,
          $align: center,
          $justify: center);
      }
    }
  }

  .in-init-ctn {
    padding: 5rem 0;
    position: relative;
    margin: auto;
    // @include inContainer($maxWidth: 85%);

    @include desktop {
      max-width: 90%;
      padding: 5rem 0 10rem;
    }

    .in-init-ctn-grid {
      @include inDisplayGrid($gap: 3.5rem);

      .in-init-ctn-grid-logo {
        margin: 0 auto;
      }

      .in-init-ctn-grid-info {
        @include inDisplayGrid($gap: 1.5rem, $text: center);

        .in-init-ctn-grid-info-icon {
          margin: 0 auto;
        }

        .in-init-ctn-grid-info-title {
          @include inDisplayGrid($gap: 0.5rem, $text: center);

          &>p {
            @include inFont($color: #ff9400,
              $size: 2rem,
              $height: 2rem,
              $weight: 800);
          }

          &>h1 {
            @include inFont($color: #7a3602,
              $size: 4.85rem,
              $height: 5rem,
              $family: 'Microbrew Soft Four 3D'
            );

            @include mobile {
              font-size: 4rem;
            }

            &>br {
              display: none;

              @include wide-sm {
                display: block;
              }
            }

            &>span {
              color: #ff9400;
            }
          }
        }

        .in-init-ctn-grid-info-categories {
          margin: 0 auto;
          padding: 2rem 2.5rem;
          border-radius: 2.5rem;
          border: 0.125rem dashed #ff9400;
          @include inDisplayGrid($gap: 1rem, $text: center);

          @include tablet {
            padding: 2rem 5rem;
          }

          @include mobile {
            padding: 2rem 1.5rem;
            border-radius: 1.5rem;
          }

          .in-init-ctn-grid-info-categories-sup {
            &>p {
              @include inFont($color: #ff9400,
                $size: 1.5rem,
                $height: 1.5rem,
                $weight: 700);
            }
          }

          .in-init-ctn-grid-info-categories-sub {
            @include inDisplayGrid($gap: 2.5rem,
              $align: flex-end,
              $justify: space-between,
              $columns: repeat(2, auto));

            @include tablet {
              gap: 1.5rem;
              grid-template-columns: 1fr;
            }

            .in-init-ctn-grid-info-categories-sub-image {
              @include tablet {
                margin: 0 auto;
              }
            }

            .in-init-ctn-grid-info-categories-sub-ctt {
              @include inDisplayGrid($gap: 2.5rem,
                $align: flex-start,
                $justify: space-between,
                $columns: repeat(2, auto));

              @include tablet {
                gap: 0.5rem;
                grid-template-columns: 1fr;
              }

              &>ul {
                @include inDisplayGrid($gap: 0.5rem);

                @include tablet {
                  text-align: center;
                }

                &>li {
                  @include inFont($color: #7a3602,
                    $size: 1.5rem,
                    $height: 1.5rem,
                    $weight: 600);
                }
              }
            }
          }
        }

        .in-init-ctn-grid-info-subtitle {
          &>p {
            @include inFont($color: #7b3603,
              $size: 1.25rem,
              $height: 2rem,
              $weight: 400);
          }
        }

        .in-init-ctn-grid-info-votenow {
          @include mobile {
            display: none;
          }

          &>span {
            margin: 0 auto;
            cursor: pointer;
            padding: 1rem 2rem;
            border-radius: 2.5rem;
            border: 0.15rem solid #f39200;
            background-image: linear-gradient(90deg, #f39100 0%, #f2a430 100%);
            @include inFont($color: white !important,
              $size: 1.5rem,
              $height: 2rem,
              $family: 'Microbrew Soft Four 3D'
            );

            &:hover {
              transition: 0.25s ease-in-out;
              filter: drop-shadow(7.5px 12.99px 10px rgba(243, 146, 0, 0.3));
            }
          }
        }

        .in-init-ctn-grid-info-daycounter {
          @include inDisplayGrid($gap: 0.5rem, $text: center);

          &>p {
            @include inFont($color: #ff9400,
              $size: 2rem,
              $height: 2rem,
              $weight: 800);
          }

          &>h1 {
            @include inFont($color: #7a3602,
              $size: 4.85rem,
              $height: 5rem,
              $family: 'Microbrew Soft Four 3D'
            );

            @include mobile {
              font-size: 4rem;
            }

            &>br {
              display: none;

              @include wide-sm {
                display: block;
              }
            }

            &>span {
              color: #ff9400;
            }
          }
        }
      }

      .in-init-ctn-grid-partiners {
        width: min-content;
        margin: auto;
        @include inDisplayGrid($gap: 5rem, $align: flex-start, $justify: center, $columns: repeat(4, auto));

        @include desktop {
          grid-template-columns: repeat(2, auto);
        }

        @include wide-md {
          grid-template-columns: repeat(3, auto);
          gap: 2rem;

          .in-init-ctn-grid-partiners-item {
            >.logos {
                margin-top: 0 !important;
              }
            &:nth-child(1) {
              grid-column: span 3;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              grid-column: span 1;
            }
          }
        }

        @include tablet {
          gap: 2.5rem;
          grid-template-columns: repeat(1, auto);
          .in-init-ctn-grid-partiners-item{
            >.logos {
                margin-top: 0 !important;
              }
          }
        }

        @include mobile {
          display: flex;
          flex-wrap: wrap;
          gap: 1.5rem;

          .in-init-ctn-grid-partiners-item {
            width: 100%;
            height: auto !important;
            text-align: center;

            >.logos{
              margin-top: 0 !important;
            }
            .patrocinio-grid {
              grid-template-columns: 1fr !important;
            }
          }

          .in-init-ctn-grid-partiners-item:nth-child(3),
          .in-init-ctn-grid-partiners-item:nth-child(4) {
            width: 40%;
            box-sizing: border-box;
            padding: 0;
            display: inline-block;
          }

          .in-init-ctn-grid-partiners-item:nth-child(3) .logos,
          .in-init-ctn-grid-partiners-item:nth-child(4) .logos {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 1rem !important;
          }
        }

        .in-init-ctn-grid-partiners-item {
          height: 100%;
          @include inDisplayGrid($gap: 1rem, $text: center, $align: center, $rows: auto 1fr);

          p {
            @include inFont($color: #793501, $size: 1rem, $weight: bold, $transform: uppercase);
          }
          >.logos {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            a {
                display: block;
            
                img {
                  max-height: 50px;
                  object-fit: contain;
                }
              }
          }

          .patrocinio-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;
          }

          .patrocinio-col {
            text-align: center;

            p {
              @include inFont($color: #793501, $size: 1rem, $weight: bold, $transform: uppercase);
              display: inline-block;
              position: relative;
              margin-bottom: 1rem;

              &::before,
              &::after {
                content: '';
                position: absolute;
                width: 50px;
                height: 1px;
                background: #793501;
                bottom: 3px;
              }

              &::before {
                left: -55px;
              }

              &::after {
                right: -55px;
              }
            }

            .logos {
              display: flex;
              justify-content: center;
              gap: 1rem;
              align-items: center;
              height: 70px;
              a {
                display: block;
            
                img {
                  max-height: 70px;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
    }
  }
}

.in-contest {
  width: 100%;
  position: relative;
  background: #f1eae6;

  .in-contest-bg {
    &>img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  .in-contest-line {
    top: 1.5rem;
    width: 100%;
    position: absolute;

    &>hr {
      border-top: 0.1rem dashed #793501;
    }
  }

  .in-contest-ctn {
    padding: 6.5rem 0;
    position: relative;
    @include inContainer($maxWidth: 65%);

    @include wide-xl {
      max-width: 70%;
    }

    @include wide-lg {
      max-width: 80%;
    }

    @include wide-md {
      max-width: 90%;
    }

    .in-contest-ctn-grid {
      @include inDisplayGrid($gap: 5rem, $text: center);

      @include tablet {
        gap: 7.5rem;
      }

      .in-contest-ctn-grid-sup {
        &>img {
          margin: 0 auto;
        }

        &>p:nth-child(2) {
          @include inFont($color: #7a3602,
            $size: 4.85rem,
            $height: 5rem,
            $family: 'Microbrew Soft Four 3D'
          );

          @include mobile {
            @include inFont($color: #7a3602,
              $size: 4rem,
              $height: 5rem,
              $family: 'Microbrew Soft Four 3D'
            );
          }
        }

        &>p:nth-child(3) {
          @include inFont($color: #7b3603,
            $size: 1.25rem,
            $height: 1.75rem,
            $weight: 400);

          &>br {
            @include mobile {
              content: '';

              &:after {
                content: ' '; // note we've added space here.
              }
            }
          }
        }
      }

      .in-contest-ctn-grid-sub {
        margin: 0 auto;
        @include inDisplayGrid($gap: 1.5rem,
          $align: flex-end,
          $justify: center,
          $columns: repeat(3, 1fr));

        @include tablet {
          gap: 7.5rem;
          padding: 0 10rem;
          grid-template-columns: 1fr;
        }

        @include mobile {
          padding: 0;
        }

        .in-contest-ctn-grid-sub-step {
          position: relative;
          border-radius: 1.5rem;
          padding: 7.5rem 2.5rem 2.5rem;
          border: 0.125rem dashed #f39100;

          @include desktop {
            padding: 7.5rem 1.5rem 1.5rem;
          }

          .in-contest-ctn-grid-sub-step-container-strip {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: transparent;
            overflow: hidden;

            .in-contest-ctn-grid-sub-step-strip {
              height: 80px;
              width: 120%;
              background-color: #b30000;
              position: absolute;
              left: -10%;
              bottom: 40%;
              transform: rotate(-15deg);
              display: flex;
              justify-content: center;
              align-items: center;

              h3 {
                font-family: 'Microbrew Soft Four 3D';
                color: #fff;
                font-size: 2.3rem;
                font-weight: 300;
              }
            }
          }

          .in-contest-ctn-grid-sub-step-image {
            &>hr {
              top: 0;
              left: 50%;
              width: 55%;
              position: absolute;
              transform: translate(-50%, -50%);
              border-top: 0.25rem solid #f1eae6;
            }

            &>img {
              top: 0;
              left: 50%;
              position: absolute;
              transform: translate(-50%, -50%);
            }
          }

          .in-contest-ctn-grid-sub-step-list {
            @include inDisplayGrid($gap: 1.5rem);

            &>p {
              text-align: center;
              @include inFont($color: #7b3603,
                $size: 1.25rem,
                $height: 1.75rem,
                $weight: 400);

              &>span {
                font-weight: 700;
              }
            }

            &>ul {
              @include inDisplayGrid($gap: 0.5rem);

              &>li {
                @include inDisplayGrid($gap: 0.5rem,
                  $align: center,
                  $columns: auto 1fr);

                &>p {
                  @include inFont($color: #7b3603,
                    $size: 1.25rem,
                    $height: 1.75rem,
                    $weight: 700);
                }
              }
            }

            &>button {
              margin: 0 auto;
              padding: 1rem 2rem;
              background: #7a3602;
              border-radius: 2.5rem;
              @include inFont($color: #f39200,
                $size: 1.25rem,
                $height: 1.75rem,
                $weight: 800);
              @include inDisplayGrid($gap: 0.5rem,
                $align: center,
                $justify: center,
                $columns: repeat(2, auto));
            }
          }

          .in-contest-ctn-grid-sub-step-award {
            @include inDisplayGrid($gap: 0.5rem, $text: center);

            &>p:nth-child(1) {
              @include inFont($color: #f39200,
                $size: 3.75rem,
                $height: 4.5rem,
                $family: 'Microbrew Soft Four 3D'
              );
            }

            &>p:nth-child(2) {
              @include inFont($color: #7b3603,
                $size: 1.25rem,
                $height: 1.75rem,
                $weight: 400);

              &>span {
                font-weight: 700;
              }
            }

            &>p:nth-child(3) {
              @include inFont($color: #f39200,
                $size: 2.5rem,
                $height: 3rem,
                $family: 'Microbrew Soft Four 3D'
              );
            }

            &>button {
              margin: 0 auto;
              padding: 1rem 2rem;
              background: #7a3602;
              border-radius: 2.5rem;
              text-align: center !important;
              @include inFont($color: #f39200,
                $size: 1.25rem,
                $height: 1.75rem,
                $weight: 800);
              @include inDisplayGrid($gap: 0.5rem,
                $align: center,
                $justify: center,
                $columns: repeat(2, auto));
            }
          }
        }
      }
    }
  }
}

.in-baker {
  width: 100%;
  position: relative;
  background-color: #f9f3ee;
  display: flex;
  justify-content: center;
  padding-top: 3rem;

  .ft_async_tag{
    #ftdiv8832505{
      width: 100% !important;
      height: auto !important;
      img#ftalt8832505{
        width: 100% !important;
        height: auto !important;
      }
    }
  }

  &>a{
    width: 100%;
    text-align: center;
    img {
      width: 85%;
      height: 100%;
      object-fit: contain;
      @include mobile{
        width: 100%;
      }
    }
  }
}

.in-step {
  width: 100%;
  position: relative;
  margin-top: -0.25rem;
  background: #f9f3ee;

  .in-step-bg {
    &>img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  .in-step-ctn {
    padding: 5rem 0;
    position: relative;
    @include inContainer($maxWidth: 95%);

    .in-step-ctn-grid {
      @include inDisplayGrid($gap: 5rem);

      .in-step-ctn-grid-publicity {
        @include inDisplayGrid($gap: 1.5rem, $text: center);

        &>p {
          @include inFont($color: #7b3603,
            $size: 0.85rem,
            $height: 1rem,
            $weight: 400,
            $transform: uppercase);
        }

        &>div {
          width: 100%;
          height: 90px;
          margin: 0 auto;
          max-width: 768px;
          @include inDisplayGrid($align: center, $text: center);

          @include mobile {
            width: 100%;
            height: auto;
          }

          img {
            height: 100%;
            width: 100%;
          }

          &>p {
            @include inFont($color: #7b3603,
              $size: 1rem,
              $height: 1rem,
              $weight: 400);
          }
        }
      }

      .in-step-ctn-grid-ctt {
        @include inDisplayGrid($gap: 3rem, $text: center);

        @include mobile {
          grid-gap: 1rem;
        }

        .in-step-ctn-grid-ctt-title {
          @include inDisplayGrid($gap: 1rem);

          .in-step-ctn-grid-ctt-title-main {
            @include inDisplayGrid($gap: 0.5rem, $text: center);

            &>img {
              margin: 0 auto;
            }

            &>p {
              @include inFont($color: #f39200,
                $size: 4.85rem,
                $height: 5rem,
                $family: 'Microbrew Soft Four 3D'
              );

              @include mobile {
                font-size: 4rem;
              }
            }
          }

          .in-step-ctn-grid-ctt-title-info {
            max-width: 1000px;
            margin: 0px auto;
            @include inDisplayGrid($gap: 0.5rem, $text: center);

            @include mobile {
              br {
                content: '';

                &:after {
                  content: ' '; // note we've added space here.
                }
              }
            }

            @include tablet {
              br {
                content: '';

                &:after {
                  content: ' '; // note we've added space here.
                }
              }
            }

            @include wide-sm {
              br {
                content: '';

                &:after {
                  content: ' '; // note we've added space here.
                }
              }
            }

            &>p:nth-child(1) {
              @include inFont($color: #7b3603,
                $size: 1.85rem,
                $height: 2rem,
                $weight: 700);
            }

            &>p:nth-child(2) {
              @include inFont($color: #7b3603,
                $size: 1.25rem,
                $height: 1.75rem,
                $weight: 400);

              @include mobile {
                font-size: 1rem;
                line-height: 1.5rem;
              }
            }
          }
        }

        .in-step-ctn-grid-ctt-cards {
          &>ul {
            width: 88%;
            margin: auto;

            &>li {
              width: 17rem;
              margin: 1rem;
              perspective: 60rem;
              display: inline-block;

              &:hover {
                .in-step-ctn-grid-ctt-cards-flip {
                  transform: rotateY(180deg);
                }
              }

              .in-step-ctn-grid-ctt-cards-flip {
                width: 100%;
                height: 24rem;
                position: relative;
                transition: transform 0.8s;
                transform-style: preserve-3d;

                .in-step-ctn-grid-ctt-cards-flip-front {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  background: white;
                  border-radius: 1.5rem;
                  backface-visibility: hidden;
                  border: 0.1rem solid #cccccc;

                  &>img {
                    width: 100%;
                    height: 12rem;
                    object-fit: cover;
                    border-radius: 1.5rem 1.5rem 0 0;
                  }

                  &>div {
                    height: 12rem;
                    padding: 1.5rem 1.5rem 2.5rem;
                    @include inDisplayGrid($gap: 1rem,
                      $text: center,
                      $rows: 1fr auto);

                    &>p {
                      @include inFont($color: #7b3603,
                        $size: 1.5rem,
                        $height: 1.5rem,
                        $weight: 700);
                    }

                    &>button {
                      margin: 0 auto;
                      background: white;
                      padding: 0.5rem 1.5rem;
                      border-radius: 2.5rem;
                      border: 0.15rem solid #f39200;
                      @include inFont($color: #f39200,
                        $size: 1.5rem,
                        $height: 2rem,
                        $family: 'Microbrew Soft Four 3D'
                      );
                    }
                  }
                }

                .in-step-ctn-grid-ctt-cards-flip-back {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  background: white;
                  border-radius: 1.5rem;
                  transform: rotateY(180deg);
                  backface-visibility: hidden;
                  border: 0.1rem solid #cccccc;

                  &>img {
                    top: 0;
                    left: 0;
                    width: 100%;
                    object-fit: cover;
                    position: absolute;
                  }

                  &>div {
                    height: 100%;
                    padding: 1.5rem;
                    position: relative;
                    display: flex;
                    text-align: center;
                    flex-direction: column;
                    justify-content: space-between;

                    &>p:nth-child(1) {
                      @include inFont($color: #7b3603,
                        $size: 1.3rem,
                        $height: 1rem,
                        $weight: 700);
                    }

                    &>p:nth-child(2) {
                      @include inFont($color: #7b3603,
                        $size: .85rem,
                        $height: .9rem,
                        $weight: 400);
                    }

                    &>button {
                      margin: 0 auto;
                      padding: 0.5rem 1.5rem;
                      border-radius: 2.5rem;
                      border: 0.15rem solid #f39200;
                      filter: drop-shadow(7.5px 12.99px 10px rgba(243, 146, 0, 0.3));
                      background-image: linear-gradient(90deg,
                          #f39100 0%,
                          #f2a430 100%);
                      @include inFont($color: white,
                        $size: 1.5rem,
                        $height: 2rem,
                        $family: 'Microbrew Soft Four 3D'
                      );
                    }
                  }
                }
              }
            }
          }
        }

        .in-step-ctn-grid-ctt-cards {

          .react-tabs {
            @include inDisplayGrid($gap: 2.5rem, $text: center);

            &__tab-list {
              border: none !important;
            }

            &__tab {
              margin: 0 .75rem;
              @include inFont($color: #9D6C4C, $size: 1.5rem, $height: 2rem, $family: 'Microbrew Soft Four 3D');

              &--selected {
                border: none;
                margin: 0 .75rem;
                border-radius: 2.5rem;
                background: #f39200;
                padding: .75rem 1.5rem;
                @include inFont($color: white, $size: 1.5rem, $height: 2rem, $family: 'Microbrew Soft Four 3D');

                &:hover {
                  transition: .25s ease-in-out;
                  filter: drop-shadow(7.5px 12.99px 10px rgba(243, 146, 0, 0.3));
                }
              }
            }

            &__tab-panel {

              &>ul {
                width: 100%;

                &>li {
                  width: 15rem;
                  margin: .5rem;
                  /* perspective: 60rem; */
                  display: inline-block;

                  /* &:hover {
                    .in-step-ctn-grid-ctt-cards-flip {
                      transform: rotateY(180deg);
                    }
                  } */

                  .in-step-ctn-grid-ctt-cards-flip {
                    width: 100%;
                    position: relative;
                    /* transition: transform 0.8s;
                    transform-style: preserve-3d; */

                    .in-step-ctn-grid-ctt-cards-flip-front {
                      width: 100%;
                      height: 100%;
                      background: white;
                      border-radius: 1.5rem;
                      border: .1rem solid #CCCCCC;
                      /* position: absolute; */
                      /* backface-visibility: hidden; */

                      &>img {
                        width: 100%;
                        height: 12rem;
                        object-fit: cover;
                        border-radius: 1.5rem 1.5rem 0 0;
                      }

                      &>div {
                        padding: 1.5rem 1.5rem 2.5rem;
                        @include inDisplayGrid($gap: .5rem, $text: center, $rows: 1fr auto);

                        &>p {
                          @include inFont($color: #7B3603, $size: 1.2rem, $height: 1.5rem, $weight: 700);
                          min-height: 54px;
                        }

                        &>span {
                          margin: 0 auto;
                          background: white;
                          padding: .5rem 1.5rem;
                          border-radius: 2.5rem;
                          border: .15rem solid #F39200;
                          cursor: pointer;
                          @include inFont($color: #F39200, $size: 1.5rem, $height: 2rem, $family: 'Microbrew Soft Four 3D');
                        }

                        &>a>span {
                          @include inFont($color: #7B3603, $size: 1.5rem, $height: 2rem, $family: 'Microbrew Soft Four 3D');
                        }
                      }
                    }

                    /* .in-step-ctn-grid-ctt-cards-flip-back {
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      background: white;
                      border-radius: 1.5rem;
                      transform: rotateY(180deg);
                      backface-visibility: hidden;
                      border: .1rem solid #CCCCCC;

                      &>img {
                        top: 0;
                        left: 0;
                        width: 100%;
                        object-fit: cover;
                        position: absolute;
                      }

                      &>div {
                        height: 12rem;
                        padding: 1.5rem;
                        position: relative;
                        @include inDisplayGrid($gap:.5em, $text:center);

                        &>p:nth-child(1) {
                          @include inFont($color:#7B3603, $size:1.5rem, $height:1.5rem, $weight:700);
                        }

                        &>p:nth-child(2) {
                          @include inFont($color:#7B3603, $size:1rem, $height:1.5rem, $weight:400);
                        }

                        &>button {
                          margin: 0 auto;
                          padding: .5rem 1.5rem;
                          border-radius: 2.5rem;
                          border: .15rem solid #F39200;
                          filter: drop-shadow(7.5px 12.99px 10px rgba(243,146,0,0.3));
                          background-image: linear-gradient(90deg, #f39100 0%, #f2a430 100%);
                          @include inFont($color:white, $size:1.5rem, $height:2rem, $family:'Microbrew Soft Four 3D');
                        }
                      }
                    } */
                  }
                }
              }
            }
          }

        }

        .in-step-ctn-grid-ctt-result {

          .container {
            margin-top: 60px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 3rem;

            .ganhador-container {
              h3 {
                @include inFont($color: #f39200,
                    $size: 2rem,
                    $height: 2rem,
                    $family: 'Microbrew Soft Four 3D'
                  );
              }
              ul {
                justify-self: center;
              }
              li {
                @include inFont($color: #7b3603,
                    $size: 1.25rem,
                    $height: 2rem,
                    $weight: 400);
                    text-align: left;
              }
            }

            @media (max-width: 550px) {
              grid-template-columns: repeat(1, 1fr);
              justify-items: center;
                .ganhador-container {
                  width: 63%;
              }
            }
            @media (min-width: 551px) and (max-width: 800px) {
              grid-template-columns: repeat(2, 1fr);
            }
          }
        }
      }
    }
  }
}

.in-feed-instagram {
  width: 100%;
  min-height: 926px;
}

.in-whatis {
  width: 100%;
  position: relative;
  background: #e3d3c5;

  .in-whatis-line1 {
    top: 1.5rem;
    width: 100%;
    position: absolute;

    &>hr {
      border-top: 0.1rem dashed #793501;
    }
  }

  .in-whatis-line2 {
    width: 100%;
    bottom: 1.5rem;
    position: absolute;

    &>hr {
      border-top: 0.1rem dashed #793501;
    }
  }

  .in-whatis-ctn {
    padding: 5rem 0;
    position: relative;
    @include inContainer($maxWidth: 65%);

    @include wide-xl {
      max-width: 70%;
    }

    @include wide-lg {
      max-width: 80%;
    }

    @include wide-md {
      max-width: 90%;
    }

    @include tablet {
      max-width: 70%;
    }

    @include mobile {
      max-width: 90%;
    }

    .in-whatis-ctn-grid {
      @include inDisplayGrid($gap: 5rem,
        $align: center,
        $justify: center,
        $columns: repeat(2, 1fr));

      @include wide-sm {
        gap: 2.5rem;
      }

      @include tablet {
        grid-template-columns: 1fr;
      }

      .in-whatis-ctn-grid-lf {
        background: #e9dbd0;
        border-radius: 1.5rem;
        padding: 2.5rem 3.5rem;
        @include inDisplayGrid($gap: 2.5rem);

        @include wide-sm {
          padding: 1.5rem;
        }

        @include tablet {
          padding: 2.5rem 3.5rem;
        }

        @include mobile {
          padding: 1.5rem;
        }

        .in-whatis-ctn-grid-lf-title {
          @include inDisplayGrid($gap: 0.5rem, $text: center);

          &>p:nth-child(1) {
            @include inFont($color: #7a3602,
              $size: 1.15rem,
              $height: 1.5rem,
              $weight: 400);
          }

          &>p:nth-child(2) {
            @include inFont($color: #ff9400,
              $size: 3.125rem,
              $height: 3.5rem,
              $family: 'Microbrew Soft Four 3D'
            );

            &>span {
              color: #7a3602;
            }
          }
        }

        .in-whatis-ctn-grid-lf-map {
          @include inDisplayGrid($gap: 2.5rem);

          .in-whatis-ctn-grid-lf-map-item {
            @include inDisplayGrid($gap: 2.5rem,
              $align: center,
              $justify: center,
              $columns: 1fr 1.25fr);

            @include wide-sm {
              grid-template-columns: auto 1fr;
            }

            @include tablet {
              grid-template-columns: 1fr 1.5fr;
            }

            @include mobile {
              gap: 1rem;
              grid-template-columns: repeat(2, 1fr);
            }

            &>img {
              margin-right: 0;
              margin-left: auto;

              @include mobile {
                margin: 0 auto;
              }
            }

            &>div {
              padding-right: 2.5rem;
              @include inDisplayGrid($gap: 0.5rem);

              @include wide-sm {
                padding-right: 0;
              }

              &>p:nth-child(1) {
                @include inFont($color: #7a3602,
                  $size: 3.125rem,
                  $height: 3.125rem,
                  $family: 'Microbrew Soft Four 3D'
                );
              }

              &>p:nth-child(2) {
                @include inFont($color: #7a3602,
                  $size: 1.25rem,
                  $height: 1.5rem,
                  $weight: 600);
              }
            }
          }
        }

        .in-whatis-ctn-grid-lf-font {
          text-align: right;
          @include inFont($color: #7a3602,
            $size: 0.85rem,
            $height: 1rem,
            $weight: 400);
        }
      }

      .in-whatis-ctn-grid-rt {
        @include inDisplayGrid($gap: 1.5rem);

        @include mobile {
          &>img {
            width: 7rem;
          }
        }

        .in-whatis-ctn-grid-rt-info {
          @include inDisplayGrid($gap: 1.5rem);

          &>p:nth-child(1) {
            @include inFont($color: #7a3602,
              $size: 4.85rem,
              $height: 5rem,
              $family: 'Microbrew Soft Four 3D'
            );

            @include mobile {
              font-size: 4rem;
              line-height: 4.25rem;
            }
          }

          &>p:nth-child(2) {
            padding-right: 8rem;
            @include inFont($color: #7b3606, $size: 1.25rem, $height: 1.5rem);

            @include wide-sm {
              padding-right: 0;
            }

            @include tablet {
              padding-right: 8rem;
            }

            @include mobile {
              padding-right: 0;
            }
          }

          &>div {
            @include inDisplayGrid($gap: 0.5rem);

            &>p:nth-child(1) {
              @include inFont($color: #7b3606,
                $size: 1.25rem,
                $height: 1.5rem,
                $weight: 700,
                $transform: uppercase);
            }

            &>p:nth-child(2) {
              padding-right: 8rem;
              @include inFont($color: #7b3606, $size: 1.25rem, $height: 1.5rem);

              @include wide-sm {
                padding-right: 0;
              }

              @include tablet {
                padding-right: 8rem;
              }

              @include mobile {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.in-banner-container{
  background: #e3d3c5;
  padding: 30px;
  @include mobile{
    padding: 0 0 10px 0;
  }
  .slider-patrocinadores{
    width: 85%;
    @include mobile{
      width: 100%;
    }

    .swiper-slide{
      display: flex;
      justify-content: center;
      align-items: center;
      a{
        width: 100%;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .swiper-button-next{
      color: #ff9400;
    }
    .swiper-button-prev{
      color: #ff9400;
    }
  }
}

.in-iframe {
  width: 100%;
  position: relative;
  background: #f9f3ee;

  .in-iframe-grid {
    position: relative;
    @include inDisplayGrid();

    .in-iframe-grid-sup {
      padding: 5rem 0;
      @include inContainer($maxWidth: 85%);
      @include inDisplayGrid($gap: 1.5rem, $text: center);

      @include desktop {
        max-width: 90%;
      }

      &>img {
        margin: 0 auto;
      }

      &>a {
        @include inFont($color: #7a3602,
          $size: 4.85rem,
          $height: 5rem,
          $family: 'Microbrew Soft Four 3D'
        );

        @include mobile {
          font-size: 4rem;
        }
      }
    }

    .in-iframe-grid-sub {}
  }
}

.in-partiners {
  width: 100%;
  position: relative;
  background: #f9f3ee;

  .in-partiners-ctn {
    padding: 0 0 5rem 0;
    position: relative;
    @include inContainer($maxWidth: 85%);

    @include desktop {
      max-width: 90%;
    }

    .in-partiners-ctn-grid {
      @include inDisplayGrid($display: flex,
        $gap: 5rem,
        $align: center,
        $justify: center);
      flex-direction: column;

      @include mobile {
        grid-template-columns: 1fr;
      }

      .in-partiners-ctn-grid-logo {
        margin: 0 auto;
      }

      .in-partiners-ctn-grid-companies {
        @include inDisplayGrid($gap: 5rem, $align: flex-start, $justify: center, $columns: repeat(4, auto));

        @include desktop {
          grid-template-columns: repeat(2, auto);
          gap: 2.5rem;
        }

        @include wide-md {
          grid-template-columns: repeat(3, auto);
          gap: 2rem;

          .in-partiners-ctn-grid-companies-item {
            &:nth-child(1) {
              grid-column: span 3;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              grid-column: span 1;
            }
          }
        }

        @include tablet {
          grid-template-columns: repeat(1, auto);
          gap: 2rem;
        }

        @include mobile {
          display: flex;
          flex-wrap: wrap;
          gap: 1.5rem;

          .in-partiners-ctn-grid-companies-item {
            width: 100%;
            text-align: center;

            .patrocinio-grid {
              grid-template-columns: 1fr !important;
            }
          }

          .in-partiners-ctn-grid-companies-item:nth-child(3),
          .in-partiners-ctn-grid-companies-item:nth-child(4) {
            display: inline-block;
            width: 40%;
            box-sizing: border-box;
            padding: 0;
          }

          .in-partiners-ctn-grid-companies-item:nth-child(3) .logos,
          .in-partiners-ctn-grid-companies-item:nth-child(4) .logos {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 1rem !important;
          }
        }

        .in-partiners-ctn-grid-companies-item {
          height: 100%;
          @include inDisplayGrid($gap: 1rem, $text: center, $align: center, $rows: auto 1fr);

          p {
            @include inFont($color: #793501, $size: 1rem, $weight: bold, $transform: uppercase);
          }

          .patrocinio-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;

            .patrocinio-col {
              text-align: center;

              p {
                @include inFont($color: #793501, $size: 1rem, $weight: bold, $transform: uppercase);
                display: inline-block;
                position: relative;
                margin-bottom: 1rem;

                &::before,
                &::after {
                  content: '';
                  position: absolute;
                  width: 50px;
                  height: 1px;
                  background: #793501;
                  bottom: 3px;
                }

                &::before {
                  left: -55px;
                }

                &::after {
                  right: -55px;
                }
              }

              .logos {
                display: flex;
                justify-content: center;
                gap: 1rem;
                align-items: center;
                height: 70px;

                a {
                  display: block;

                  img {
                    max-height: 70px;
                    object-fit: contain;
                  }
                }
              }
            }
          }

          .logos {
            display: flex;
            justify-content: center;
            align-items: center;

            a {
              display: block;

              img {
                max-height: 50px;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }
}

.in-footer {
  width: 100%;
  position: relative;
  background: white;

  .in-footer-ctn {
    padding: 1.5rem 0;
    @include inContainer($maxWidth: 85%);

    @include desktop {
      max-width: 90%;
    }

    @include mobile {
      padding: 1.5rem 0 7.5rem;
    }

    .in-footer-ctn-grid {
      @include inDisplayGrid($gap: 2.5rem,
        $align: center,
        $justify: space-between,
        $columns: repeat(2, auto));

      @include tablet {
        text-align: center;
        grid-template-columns: 1fr;
      }

      &>p {
        &>span{
          cursor: pointer;
          &:hover{
            text-decoration: underline;
          }
        }
        @include inFont($color: #7b3603,
          $size: 1rem,
          $height: 1.5rem,
          $weight: 400);
      }

      &>img {
        margin: 0 auto;
      }
    }
  }
}

@keyframes shadow-pulse {
  from {
    box-shadow: 0 0 0 0 rgba(243, 146, 0, 0.75);
  }

  to {
    box-shadow: 0 0 0 0.75rem rgba(243, 146, 0, 0);
  }
}

.in-home-modal-title {
  @include inFont($color: #ff9400,
    $size: 3rem,
    $height: 3.5rem,
    $family: 'Microbrew Soft Four 3D'
  );
}