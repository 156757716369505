.popup {
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: #dacabc;
  color: #7b3602;
  font-size: 1.25rem;
  line-height: 2.5rem;
  font-weight: 400;
  font-family: 'Nunito';
  border-radius: 5px;
  padding: 35px;
  text-align: center;
}

button {
  margin-top: 15px;

  padding: 0.9rem 2rem;
  border-radius: 999px;
  background-color: #7b3602;
  color: #dacabc;
  font-size: 1.25rem;
}
