@import '../constants/mixins';
@import '../constants/generics';
@import '../constants/fontface';

.in-details {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.8);

  .in-details-modal {
    top: 5%;
    width: 100%;
    max-width: 55%;
    overflow: auto;
    margin: 0 auto;
    max-height: 90%;
    position: relative;
    border-radius: 1rem;
    background: #F6F0EB;
    border: .1rem solid #c0c0c0;

    &::-webkit-scrollbar {
      height: 100%;
      width: .5rem;
    }
    
    &::-webkit-scrollbar-track {
      background:#E6E6E6;
      border-radius: 5rem;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 5rem;
      background: #7B3603;
    }

    @include wide-lg {
      max-width: 60%;
    }

    @include wide-md {
      max-width: 70%;
    }

    @include wide-sm {
      max-width: 80%;
    }

    @include mobile {
      max-width: 90%;
    }

    .in-details-modal-grid {
      @include inDisplayGrid($gap:0);

      .in-details-modal-grid-sup {
        width: 100%;
        height: 20rem;

        @include mobile {
          height: 15rem;
        }
  
        &>img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem 1rem 0 0;
        }
      }

      .in-details-modal-grid-sub {
        padding: 2.5rem;
        @include inDisplayGrid($gap:5rem, $align:flex-start, $justify:space-between, $columns:repeat(2,auto));

        @include desktop {
          grid-template-columns: repeat(2, 1fr);
        }
        
        @include mobile {
          grid-template-columns: 1fr;
          padding: 1.5rem;
        }

        .in-details-modal-grid-sub-lf {
          @include inDisplayGrid($gap:1.5rem);   

          .in-details-modal-grid-sub-lf-title {
            @include inDisplayGrid($gap:.5rem);   

            &>p {
              @include inFont($color:#F39200, $size:3rem, $height:3rem, $family:'Microbrew Soft Four 3D');
            }

            &>div {
              @include inDisplayGrid($gap:.5rem, $align:center, $columns:repeat(2,auto));   

              &>p {
                @include inFont($color:#7B3603, $size:1rem, $height:1.25rem);
              }
            }
          }

          .in-details-modal-grid-sub-lf-dcpt {
            &>p {
              @include inFont($color:#7B3603, $size:1rem, $height:1.35rem);
            }
          }
        }
        
        .in-details-modal-grid-sub-rt {
          padding-top: 1rem;
          @include inDisplayGrid($gap:3.5rem); 
            
          .in-details-modal-grid-sub-rt-votenow {
            &>button {
              width: 100%;
              border-radius: 1rem;
              padding: 1rem 1.5rem;
              background: transparent;
              border: .15rem solid #F39200;
              @include inFont($color:#F39200, $size:2rem, $height:2rem, $family:'Microbrew Soft Four 3D');
            }
          }
            
          .in-details-modal-grid-sub-rt-socials {
            @include inDisplayGrid($gap:1rem, $text:center);

            @include mobile{
              padding-bottom: 3rem;
            }
            
            &>p {
              @include inFont($color:#7B3603, $size:1.15rem, $height:2rem);
            }

            &>div {
              @include inDisplayGrid($gap:1.5rem, $text:center, $align:center, $justify:center, $columns:repeat(4,auto)); 
            
              svg {
                color:#7B3603;
                font-size: 1.25rem;
                cursor: pointer;
              }
            }
          }
        }

        .in-footer-patrocinadores{
          grid-column: 1 / -1;

          .in-partiners-ctn-grid-companies {
              @include inDisplayGrid($gap: 1rem, $align: flex-start, $justify: center, $columns: repeat(4, auto));
          
              @include wide-md {
                grid-template-columns: repeat(4, auto);
                gap: 2rem;
              }
          
              @include tablet {
                grid-template-columns: repeat(1, 1fr);
                gap: 2rem;
              }
          
              @include mobile {
                display: flex;
                flex-wrap: wrap;
                gap: 1.5rem;
          
                .in-partiners-ctn-grid-companies-item {
                  width: 100%;
                  text-align: center;
          
                  .patrocinio-grid {
                    grid-template-columns: 1fr !important;
                    img{
                      width: 100%;
                    }
                  }
                }
          
                .in-partiners-ctn-grid-companies-item:nth-child(3),
                .in-partiners-ctn-grid-companies-item:nth-child(4) {
                  display: inline-block;
                  width: 40%;
                  box-sizing: border-box;
                  padding: 0;
                }
          
                .in-partiners-ctn-grid-companies-item:nth-child(3) .logos,
                .in-partiners-ctn-grid-companies-item:nth-child(4) .logos {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 1rem !important;
                }
              }
          
              .in-partiners-ctn-grid-companies-item {
                height: 100%;
                @include inDisplayGrid($gap: 1rem, $text: center, $align: center, $rows: auto 1fr);
          
                p {
                  @include inFont($color: #793501, $size: 1rem, $weight: bold, $transform: uppercase);
                }
          
                .patrocinio-grid {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  gap: 2rem;
          
                  .patrocinio-col {
                    text-align: center;
          
                    p {
                      @include inFont($color: #793501, $size: .8rem, $weight: bold, $transform: uppercase);
                      display: inline-block;
                      position: relative;
                      margin-bottom: 1rem;
          
                      &::before,
                      &::after {
                        content: '';
                        position: absolute;
                        width: 50px;
                        height: 1px;
                        background: #793501;
                        bottom: 3px;
                      }
          
                      &::before {
                        left: -55px;
                      }
          
                      &::after {
                        right: -55px;
                      }
                    }
          
                    .logos {
                      display: flex;
                      justify-content: center;
                      gap: 1rem;
                      align-items: center;
          
                      a {
                        display: block;
          
                        img {
                          width: 100%;
                          max-height: 70px;
                          object-fit: contain;
                          @include mobile{
                            width: 85%;
                          }
                        }
                      }
                    }
                  }
                }
          
                .logos {
                  display: flex;
                  justify-content: center;
                  align-items: center;
          
                  a {
                    display: block;
          
                    img {
                      width: 80%;
                      max-height: 40px;
                      object-fit: contain;
                    }
                  }
                }
              }
            }
        }
      }
    }

    .image{
      width: 100%;
      height: 25%;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .container{
      padding: 2rem 0;
      @include inContainer($maxWidth:85%, $height: auto);
      @include inDisplayGrid($gap: 1rem, $text: center, $columns: 3fr 2fr);

      &>div{

        &:first-of-type{
          @include inDisplayGrid($display: flex, $gap: 1rem, $text: center);
          flex-direction: column;

          .title{
            text-align: center;
          }

          .endereco{

          }

          .descricao{
            text-align: left;
          }

        }

        &:nth-of-type(2){
          @include inDisplayGrid($display: flex, $gap: 3rem, $text: center);
          flex-direction: column;

          button{
            padding: 1rem;
            border: 1px solid #F39200;
            border-radius: 1rem;
            color: #F39200;
            font-size: 1.2rem;
            background: transparent;
          }

          .share{
            &>p{
              margin-bottom: 1rem;
            }
            .social-medias{
              @include inDisplayGrid($display: flex, $justify: center, $gap: 2rem);
              font-size: 1.2rem;
            }
          }

        }

      }
      
    }
  }
}