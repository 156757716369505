@import '../src/assets/scss/constants/generics.scss';
@import '../src/assets/scss/constants/mixins.scss';

html,
body {
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: inherit;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  display: inline-block;
}

hr {
  border-top: inherit;
}

p,
ul,
label {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:visited {
    color: inherit;
    text-decoration: none;
  }

  &:focus,
  &:active {
    outline: inherit;
  }
}

i:hover {
  cursor: pointer;
  transition: .25s ease-in;
}

button {
  cursor: pointer;

  &:focus {
    outline: inherit;
  }
}

.grecaptcha-badge {
  display: none!important;
}

.CookieConsent {
  width: auto!important;
  margin: .5rem!important;
  z-index: 999999999!important;
  border-radius: .5rem!important;
  padding: 1rem 1.5rem!important;
  @include inDisplayGrid($gap:1.5rem!important, $align:center!important, $justify:center!important, $columns:1fr auto!important);

  &>div {
    margin: 0!important;
    
    p {
      margin: 0!important;
      line-height: 1.5rem!important;
    }
  }

  button {
    margin: 0!important;
    color: white!important;
    border-radius: .5rem!important;
    padding: .75rem 1.5rem!important;
  }
}