@import 'constants/mixins';
@import 'constants/generics';
@import 'constants/fontface';

.in-vote{
  width: 100%;
  min-height: 100vh;
  // position: relative;
  overflow: auto;

  .in-vote-close {
    top: 3.5rem;
    z-index: 30;
    right: 2.5rem;
    position: absolute;

    @include tablet {
      top: 1.5rem;
      right: 1.5rem;
    }

    @include mobile {
      top: 1rem;
      right: 1rem;
    }

    &>a {
      background: #F39100;
      border-radius: 2.5rem;
      padding: .75rem 1.5rem;
      @include inFont($color:white, $size:1.15rem, $height:1.15rem, $weight:700);
      @include inDisplayGrid($gap:.5rem, $align:center, $justify:center, $columns:repeat(2,auto));
      
      &:hover {
        transition: .25s ease-in;
        filter: drop-shadow(7.5px 12.99px 10px rgba(243,146,0,0.3));
      }

      @include mobile {
        gap: .25rem;
        font-size: .85rem;
        line-height: .85rem;
        padding: .75rem 1.25rem;

        &>img {
          width: 75%;
        }
      }
    }
  }

  .in-vote-grid {
    position: relative;
    @include inDisplayGrid($columns:1fr 2.5fr);

    @include wide-lg {
      grid-template-columns: 1fr 2fr;
    }
    
    @include wide-md {
      grid-template-columns: 1fr 1.5fr;
    }

    @include tablet {
      grid-template-columns: 1fr;
    }
  }
}