@import '../constants/mixins';
@import '../constants/generics';

.in-map {
  width: 100%;
  position: relative;

  .in-mapa-google {
    width: 100%;
    height: 100vh;
  }

  .leaflet-container {
    z-index: 19;
    width: 100%;
    height: 100vh;

    @include mobile {
      height: 60vh !important;
    }
  }
}
